<template>
  <section class="scene-intro" id="intro">
    <div class="static-container">
      <h1 class="title">
        <span class="func">G</span>
        <span class="bram">.Bram()</span>
      </h1>

      <div class="std">
        <p class="-pink">Bram Goudsmid</p>
        <p class="-gray">
          Software Developer<br>
          designer, and Sandwich.
        </p>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'Intro'
}
</script>