<template>
  <div>
    <div class="spine"></div>
    <div class="spine-target">
      <div class="circle"></div>
      <div class="pulse"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Spine'
}
</script>